import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import {
  authorFetcher,
  topicsFetcher,
} from 'core/fetchers/authorPage';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withPageHocs from 'core/components/withPageHocs';

import { PageIndent } from 'core/components/Wrappers';
import AuthorPage from 'core/components/AuthorPage';
import ColumnLayout from 'core/components/ColumnLayout';

import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';
import { PageBoroda } from 'site/components/Wrappers';

import footerFetcher from 'site/fetchers/footer';

import AuthorPageCard from './AuthorPageCard';
import { CardVertical1 } from 'site/cards/CardVertical';
import CardHorizontal from 'site/cards/CardHorizontal';


function Author({ footerContent, author, topics, isMobile }) {
  return (
    <PageBoroda content={footerContent}>
      <PageIndent>
        <ColumnLayout rightColumn={<InnerSideColumn showUgcPromo />}>
          <AuthorPage
            author={author}
            topics={topics}
            card={isMobile ? CardVertical1 : CardHorizontal}
            interitemSpacing={20}
            authorCard={AuthorPageCard}
          />
        </ColumnLayout>
      </PageIndent>
      <Boroda />
    </PageBoroda>
  );
}

Author.propTypes = {
  topics: PropTypes.object,
  author: PropTypes.object,
  footerContent: PropTypes.array,
  isMobile: PropTypes.bool,
};

const dataProvider = resolve({
  author: authorFetcher(),
  topics: topicsFetcher({
    limit: 15,
    include: 'rubric,image',
  }),
  footerContent: footerFetcher(),
});

export default withPageHocs(dataProvider)(withBreakpoint(Author));
